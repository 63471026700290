import { ModalsEnum } from 'types/enums';
import {
    InteractionSource,
    ModalName,
} from 'utils/analytics/AnalyticsV2Service.generated';

export const modalInstrumentation = {
    [ModalsEnum.freeUserFreeDownloadUpsell]: {
        'L - Modal': 'Pricing Sample',
        'L - User Interaction Source': 'Payment gate',
    },
    [ModalsEnum.freeUserPaidDownloadUpsell]: {
        'L - Modal': 'Pricing Sample',
        'L - User Interaction Source': 'Payment gate',
    },
    [ModalsEnum.paidUserPaidDownloadUpsell]: {
        'L - Modal': 'Pricing Sample',
        'L - User Interaction Source': 'Payment gate',
    },
    [ModalsEnum.creditsWarning]: {
        'L - Modal': 'Credits Warning',
        'L - User Interaction Source': '',
    },
    [ModalsEnum.poorStandingOrSuspended]: {
        'L - Modal': 'Poor Standing Or Suspended',
        'L - User Interaction Source': '',
    },
    [ModalsEnum.removeSamplesFromCollection]: {
        'L - Modal': 'Delete Samples',
        'L - User Interaction Source': '',
    },
    [ModalsEnum.deleteCollectionModal]: {
        'L - Modal': 'Delete Collection',
        'L - User Interaction Source': '',
    },
    [ModalsEnum.createCollectionModal]: {
        'L - Modal': 'Create Collection',
        'L - User Interaction Source': '',
    },
    [ModalsEnum.editCollectionModal]: {
        'L - Modal': 'Edit Collection',
        'L - User Interaction Source': '',
    },
    [ModalsEnum.keyboardCheatSheet]: {
        'L - Modal': 'Keyboard CheatSheet Modal',
        'L - User Interaction Source': '',
    },
};

export const modalInstrumentationV2 = {
    [ModalsEnum.freeUserFreeDownloadUpsell]: {
        modalName: ModalName.UpsellModalBasicDownload,
        interactionSource: InteractionSource.AssetAction,
    },
    [ModalsEnum.freeUserPaidDownloadUpsell]: {
        modalName: ModalName.UpsellModalFreeUserPaidDownload,
        interactionSource: InteractionSource.Automatic,
    },
    [ModalsEnum.paidUserPaidDownloadUpsell]: {
        modalName: ModalName.UpsellModalPaidUserPaidDownload,
        interactionSource: InteractionSource.Automatic,
    },
    [ModalsEnum.creditsWarning]: undefined,
    [ModalsEnum.removeSamplesFromCollection]: undefined,
    [ModalsEnum.deleteCollectionModal]: undefined,
    [ModalsEnum.createCollectionModal]: undefined,
    [ModalsEnum.editCollectionModal]: undefined,
    [ModalsEnum.poorStandingOrSuspended]: undefined,
    [ModalsEnum.keyboardCheatSheet]: undefined,
    [ModalsEnum.sweetenerUpsellModal]: undefined,
    [ModalsEnum.planPaused]: undefined,
    [ModalsEnum.activateTrialSubscription]: undefined,
    [ModalsEnum.trialUpsellModal]: undefined,
};
