import getStaticSeo from 'constants/staticSeo';
import { t } from '@lingui/macro';
import { useSeoMetaData } from 'hooks';
import { BrowseSeoType } from 'types/seoType';
import { RoutePaths } from 'types/enums';
import { useMatch } from 'react-router-dom';
import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import { useLingui } from '@lingui/react';

type SeoTemplate = {
    title: (translatedWildcard: string) => string;
    description: (lowerCaseWildcard: string) => string;
    header: (translatedWildcard: string) => string;
    byline: (lowerCaseWildcard: string) => string;
};

const seoTemplates: Record<string, SeoTemplate> = {
    instruments: {
        title: (translatedWildcard) =>
            t`${translatedWildcard} Samples & Loops - 100% Royalty Free`,
        description: (lowerCaseWildcard) =>
            t`Download 100% royalty free ${lowerCaseWildcard} sample packs from leading labels featuring the best loops, beats and sounds for your music or creative projects. Get access to an expansive library of samples with your LANDR Studio subscription.`,
        header: (translatedWildcard) =>
            t`${translatedWildcard} Samples & Loops`,
        byline: (lowerCaseWildcard) =>
            t`Explore our collection 100% royalty-free ${lowerCaseWildcard} samples & loops. LANDR's ${lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.`,
    },
    genres: {
        title: (translatedWildcard) =>
            t`${translatedWildcard} Samples & Loops - 100% Royalty Free`,
        description: (lowerCaseWildcard) =>
            t`High quality & royalty free ${lowerCaseWildcard} sample packs from the world's top audio sample labels & producers. Find the ${lowerCaseWildcard} samples, loops, beats & one-shots you need for your music project at LANDR Samples.`,
        header: (translatedWildcard) =>
            t`${translatedWildcard} Samples & Loops`,
        byline: (lowerCaseWildcard) =>
            t`Explore our collection 100% royalty-free ${lowerCaseWildcard} samples & loops. LANDR's ${lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.`,
    },
    sfxs: {
        title: (translatedWildcard) =>
            t`Download ${translatedWildcard} Samples & Sound Effects - 100% Royalty Free`,
        description: (lowerCaseWildcard) =>
            t`Download royalty free ${lowerCaseWildcard} sound effects. Find high quality ${lowerCaseWildcard} sounds & samples for your next creative project with LANDR in MP3 or WAV format.`,
        header: (translatedWildcard) => t`${translatedWildcard} Sound Effects`,
        byline: (lowerCaseWildcard) =>
            t`Explore our collection 100% royalty-free ${lowerCaseWildcard} samples & loops. LANDR's ${lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.`,
    },
};
const useGetWildcardSeoFallback = () => {
    const { i18n } = useLingui();
    const toLocalizedPath = useToLocalizedPath();

    const routes = {
        instruments: useMatch(
            toLocalizedPath(`/${RoutePaths.Instruments}/:wildcard`),
        ),
        genres: useMatch(toLocalizedPath(`/${RoutePaths.Genres}/:wildcard`)),
        sfxs: useMatch(toLocalizedPath(`/${RoutePaths.Sfxs}/:wildcard`)),
    };

    const match = Object.values(routes).find((route) => route !== null);
    const routeType = Object.entries(routes).find(
        ([_, route]) => route !== null,
    )?.[0];

    if (!match?.params.wildcard || !routeType) {
        return {};
    }

    const translatedWildcard = i18n._(match.params.wildcard);
    const lowerCaseWildcard = translatedWildcard.toLowerCase();
    const template = seoTemplates[routeType];

    return {
        title: template.title(translatedWildcard),
        description: template.description(lowerCaseWildcard),
        header: template.header(translatedWildcard),
        byline: template.byline(lowerCaseWildcard),
    };
};

const useBrowseSeo = (): {
    loading: boolean;
    metaData: BrowseSeoType;
} => {
    const { loading, seoMetaData } = useSeoMetaData({ withWildcard: false });

    const wildcardSeoFallback = useGetWildcardSeoFallback();

    const metaData = {
        ...getStaticSeo().browse,
        ...wildcardSeoFallback,
        ...(seoMetaData?.title && { title: seoMetaData.title }),
        ...(seoMetaData?.description && {
            description: seoMetaData.description,
        }),
        ...(seoMetaData?.header && { header: seoMetaData.header }),
        ...(seoMetaData?.byline && { byline: seoMetaData.byline }),
    };

    return { loading, metaData };
};

export default useBrowseSeo;
