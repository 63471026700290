import { BrandtypeSlug } from 'constants/brandtypeSlug';
import { t } from '@lingui/macro';

// Default
const seoFallbackDefaultTitle = (repackName: string) =>
    t`${repackName} - Sample Pack`;

const seoFallbackDefaultDescription = ({
    repackName,
    firstGenre,
    secondGenre,
}: {
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
}) => {
    if (firstGenre && secondGenre) {
        return t`Download ${repackName}, a sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, ${secondGenre} loops, one-shots, drum kits and more on LANDR Samples.`;
    } else if (firstGenre) {
        return t`Download ${repackName}, a sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.`;
    }

    return t`Download ${repackName}, a sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.`;
};

// ArtistPicks
const seoFallbackArtistPicksDescription = (repackName: string) =>
    t`${repackName} Samples – custom built sample packs, one shots, beats, percussions, and more — for you to download and use in your creative projects.`;

// Beat Starters
const seoFallbackBeatStartersTitle = (repackName: string) =>
    t`${repackName} - Sample Pack - Construction Kits`;

const seoFallbackBeatStartersDescription = ({
    repackName,
    firstGenre,
}: {
    repackName: string;
    firstGenre?: string;
}) => {
    if (firstGenre) {
        return t`Download the ${repackName} sample pack on LANDR Samples. A ${firstGenre} construction kit to inspire you when starting a new beat. Royalty-free samples, loops, one-shots, drum kits and more.`;
    }

    return t`Download the ${repackName} sample pack on LANDR Samples. A construction kit to inspire you when starting a new beat. Royalty-free samples, loops, one-shots, drum kits and more.`;
};

// Creator Packs
const seoFallbackCreatorDescription = (repackName: string) =>
    t`Get inspired by the ${repackName} Creator kit. Creator sample packs are made to inspire your music creations. Add up to 8 loops, adjust key and BPM and layer sounds to sketch out your next track.`;

// Drum Kits
const seoFallbackDrumKitsTitle = (repackName: string) =>
    t`${repackName} Samples`;

const seoFallbackDrumKitsDescription = (repackName: string) =>
    t`${repackName} samples – drums, bass, 808s, percussion, kicks, clap, hi-hat and more — for you to download and use in your productions.`;

// Drum Racks
const seoFallbackDrumRacksDescription = (repackName: string) =>
    t`${repackName} Samples – custom built sample packs one shots, beats, percussions, and more — for you to download and use in your creative projects.`;

// Free
const seoFallbackFreeTitle = (repackName: string) =>
    t`The ${repackName} - Sample Pack`;

const seoFallbackFreeDescription = ({
    repackName,
    firstGenre,
    secondGenre,
}: {
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
}) => {
    if (firstGenre && secondGenre) {
        return t`Download the ${repackName} sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, ${secondGenre} loops, one-shots, drum kits and more on LANDR Samples.`;
    } else if (firstGenre) {
        return t`Download the ${repackName} sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.`;
    }

    return t`Download the ${repackName} sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.`;
};

// LANDR Select
const seoFallbackLandrSelectDescription = ({
    repackName,
    firstGenre,
}: {
    repackName: string;
    firstGenre?: string;
}) => {
    if (firstGenre) {
        return t`Download ${repackName}, a ${firstGenre} sample pack from LANDR Samples. Find the best royalty-free samples, loops, vocals, one-shots, drum kits and more on LANDR Samples.`;
    }

    return t`Download ${repackName}, a sample pack from LANDR Samples. Find the best royalty-free samples, loops, vocals, one-shots, drum kits and more on LANDR Samples.`;
};

// Lofi
const seoFallbackLofiDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t`Download ${repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, ${instrument} loops, one-shots, drum kits and more on LANDR Samples.`;
    } else if (firstGenre) {
        return t`Download ${repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.`;
    } else if (instrument) {
        return t`Download ${repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free samples, ${instrument} loops, one-shots, drum kits and more on LANDR Samples.`;
    }

    return t`Download ${repackName}, a lofi sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.`;
};

// Premium Packs
const seoFallbackPremiumPacksTitle = (repackName: string) =>
    t`${repackName} Samples - Sample Pack`;

const seoFallbackPremiumPacksDescription = ({
    repackName,
    instrument,
}: {
    repackName: string;
    instrument?: string;
}) => {
    if (instrument) {
        return t`${repackName} samples – premium ${instrument} samples, one shots, loops, vocals, drum kits and more — for you to download and use in your productions.`;
    }

    return t`${repackName} samples – premium samples, one shots, loops, vocals, drum kits and more — for you to download and use in your productions.`;
};

// Quick Picks
const seoFallbackQuickPicksTitle = (repackName: string) =>
    t`Royalty Free ${repackName} Samples`;

const seoFallbackQuickPicksDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t`${repackName} samples – ${instrument} samples, ${firstGenre} loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.`;
    } else if (firstGenre) {
        return t`${repackName} samples – ${firstGenre} loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.`;
    } else if (instrument) {
        return t`${repackName} samples – ${instrument} samples, loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.`;
    }

    return t`${repackName} samples – loops, one shots, phrases, melodies, chords, licks and more — for you to download and use in your productions.`;
};

// SFX
const seoFallbackSfxTitle = (repackName: string) =>
    t`${repackName} Sound Effect Sample Pack`;

const seoFallbackSfxDescription = ({
    repackName,
    instrument,
}: {
    repackName: string;
    instrument?: string;
}) => {
    if (instrument) {
        return t`Download the ${repackName} sound effect sample pack from LANDR Samples. Easy to use, royalty free ${instrument} samples for your creative projects.`;
    }

    return t`Download the ${repackName} sound effect sample pack from LANDR Samples. Easy to use, royalty free samples for your creative projects.`;
};

// Top Samples
const seoFallbackTopSamplesTitle = (repackName: string) =>
    t`${repackName} - Top Charts`;

const seoFallbackTopSamplesDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t`Download the ${repackName} sample pack containing today's best ${firstGenre} loops, vocals, drum kits & ${instrument} samples.`;
    } else if (firstGenre) {
        return t`Download the ${repackName} sample pack containing today's best ${firstGenre} loops, vocals, drum kits & samples.`;
    } else if (instrument) {
        return t`Download the ${repackName} sample pack containing today's best loops, vocals, drum kits & ${instrument} samples.`;
    }

    return t`Download the ${repackName} sample pack containing today's best loops, vocals, drum kits & samples.`;
};

// Trap
const seoFallbackTrapDescription = ({
    repackName,
    firstGenre,
    instrument,
}: {
    repackName: string;
    firstGenre?: string;
    instrument?: string;
}) => {
    if (firstGenre && instrument) {
        return t`Download ${repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, ${instrument} loops, one-shots, drum kits and more on LANDR Samples.`;
    } else if (firstGenre) {
        return t`Download ${repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free ${firstGenre} samples, loops, one-shots, drum kits and more on LANDR Samples.`;
    } else if (instrument) {
        return t`Download ${repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free samples, ${instrument} loops, one-shots, drum kits and more on LANDR Samples.`;
    }

    return t`Download ${repackName}, a trap sample pack from LANDR Samples. Find the best royalty-free samples, loops, one-shots, drum kits and more on LANDR Samples.`;
};

// Type Beats
const seoFallbackTypeBeatsDescription = ({
    repackName,
    firstGenre,
    secondGenre,
}: {
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
}) => {
    if (firstGenre && secondGenre) {
        return t`${repackName} samples – Royalty free ${firstGenre} samples, ${secondGenre} beats, loops & vocals in the style of ${repackName} — for you to download and use in your productions.`;
    } else if (firstGenre) {
        return t`${repackName} samples – Royalty free ${firstGenre} samples, beats, loops & vocals in the style of ${repackName} — for you to download and use in your productions.`;
    }

    return t`${repackName} samples – Royalty free samples, beats, loops & vocals in the style of ${repackName} — for you to download and use in your productions.`;
};

export const getRePackSeoFallback = ({
    brandtypeSlug,
    repackName,
    firstGenre,
    secondGenre,
    instrument,
}: {
    brandtypeSlug?: string;
    repackName: string;
    firstGenre?: string;
    secondGenre?: string;
    instrument?: string;
}): {
    title: string;
    description: string;
} => {
    switch (brandtypeSlug) {
        case BrandtypeSlug.ArtistPicks:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackArtistPicksDescription(repackName),
            };
        case BrandtypeSlug.BeatStarters:
            return {
                title: seoFallbackBeatStartersTitle(repackName),
                description: seoFallbackBeatStartersDescription({
                    repackName,
                    firstGenre,
                }),
            };

        case BrandtypeSlug.Creator:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackCreatorDescription(repackName),
            };
        case BrandtypeSlug.DrumKits:
            return {
                title: seoFallbackDrumKitsTitle(repackName),
                description: seoFallbackDrumKitsDescription(repackName),
            };

        case BrandtypeSlug.DrumRacks:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackDrumRacksDescription(repackName),
            };

        case BrandtypeSlug.Free:
            return {
                title: seoFallbackFreeTitle(repackName),
                description: seoFallbackFreeDescription({
                    repackName,
                    firstGenre,
                    secondGenre,
                }),
            };
        case BrandtypeSlug.LandrSelect:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackLandrSelectDescription({
                    repackName,
                    firstGenre,
                }),
            };

        case BrandtypeSlug.Lofi:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackLofiDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };

        case BrandtypeSlug.PremiumPacks:
            return {
                title: seoFallbackPremiumPacksTitle(repackName),
                description: seoFallbackPremiumPacksDescription({
                    repackName,
                    instrument,
                }),
            };

        case BrandtypeSlug.QuickPicks:
            return {
                title: seoFallbackQuickPicksTitle(repackName),
                description: seoFallbackQuickPicksDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };

        case BrandtypeSlug.Sfx:
            return {
                title: seoFallbackSfxTitle(repackName),
                description: seoFallbackSfxDescription({
                    repackName,
                    instrument,
                }),
            };

        case BrandtypeSlug.TopSamples:
            return {
                title: seoFallbackTopSamplesTitle(repackName),
                description: seoFallbackTopSamplesDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };

        case BrandtypeSlug.Trap:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackTrapDescription({
                    repackName,
                    firstGenre,
                    instrument,
                }),
            };
        case BrandtypeSlug.TypeBeats:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackTypeBeatsDescription({
                    repackName,
                    firstGenre,
                    secondGenre,
                }),
            };
        default:
            return {
                title: seoFallbackDefaultTitle(repackName),
                description: seoFallbackDefaultDescription({
                    repackName,
                    firstGenre,
                    secondGenre,
                }),
            };
    }
};
