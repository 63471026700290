import { KeyRoot, KeySign, CreatorEvents } from 'types/enums';
import { KeyQuality, KeyRootApi, Sample } from 'types/generated/graphql';
import { CreatorTrackMapType } from 'libs/CreatorSession/CreatorSession.types';
import { Observer } from 'utils/eventObserver';

export enum CreatorSampleFetchingStatuses {
    SAMPLE = 'sample',
    STREAM_URL = 'stream_url',
    STREAM = 'stream',
}

export type CreatorBpmMultiplier = 'None' | 'Half' | 'Double';

export type CreatorSampleStatusType = {
    onAdded: () => void;
    streamUrl: string;
    stream?: Response;
};

export type CreatorSampleControlsType = {
    semitoneShift: number;
    timeFactor: number;
    timeFactorBeforeMultiplier: number;
    bpmMultiplier: CreatorBpmMultiplier;
    bpm: number;
    gain: number;
    pitchTranspose: number | undefined;
    recommendedValues: number[];
    scaledDuration: number | null;
};

export type CreatorSampleMetaType = CreatorSampleControlsType &
    CreatorSampleStatusType;

export type CreatorSampleType = Sample &
    CreatorSampleMetaType & {
        fetching: false | CreatorSampleFetchingStatuses;
        isAutoload: boolean | undefined;
    };

export type CreatorSamplesMapType = Map<string, CreatorSampleType>;

export type CreatorContextStateType = {
    instrumentationSessionId: string;
    bpm: number;
    isCreatorOpen: boolean;
    isRemoveAllModalOpen: boolean;
    isLoadSessionModalOpen: boolean;
    isShareModalOpen: boolean;
    isSessionProcessing: boolean;
    isUiPaused: boolean;
    keyQuality: KeyQuality;
    keySign: KeySign;
    keyRoot: KeyRootApi;
    normalizedRootValue: KeyRoot;
    onboarding: string[];
    samples: CreatorSamplesMapType;
    isSessionBpmDefined: boolean;
    isSessionKeyDefined: boolean;
};

export type CreatorContextComputedStateType = {
    samplesInSession: CreatorSamplesMapType;
};

export type CreatorEventPayloadType =
    | boolean
    | CreatorSampleMetaType
    | CreatorTrackMapType;

export type CreatorSubscriptionType = {
    subscribe: (
        event: CreatorEvents,
        observer: Observer<CreatorEventPayloadType>,
    ) => void;
    unsubscribe: (
        event: CreatorEvents,
        observer: Observer<CreatorEventPayloadType>,
    ) => void;
};

export type CreatorContextDispatch = React.Dispatch<CreatorAction>;

export type CreatorContextType = {
    state: CreatorContextStateType & CreatorContextComputedStateType;
    dispatch: CreatorContextDispatch;
    __resumeContext: () => void;
    autoOpenAndPlay: () => void;
    addTrack: (guid: string, onAdded?: () => void, bpm?: number) => void;
    closeCreator: () => void;
    closeLoadSessionModal: () => void;
    closeRemoveAllModal: () => void;
    closeShareModal: () => void;
    dispatchLoadSession: ({
        session,
        isAutoload,
        withAutoplay,
    }: {
        session?: CreatorSerializableState;
        isAutoload?: boolean;
        withAutoplay?: boolean;
    }) => void;
    getCurrentTime: () => number;
    getTrackGain: (guid: string) => number;
    isLandingPageVisited: boolean;
    isVisible: boolean;
    isInitialized: boolean;
    loadSessionByGuid: ({
        sessionGuid,
        isAutoload,
        withAutoplay,
    }: {
        sessionGuid: string;
        isAutoload?: boolean;
        withAutoplay?: boolean;
    }) => void;
    openCreator: () => void;
    openLoadSessionModal: () => void;
    openRemoveAllModal: () => void;
    openShareModal: () => void;
    pause: () => void;
    removeTrack: (guid: string, sampleName?: string | undefined) => void;
    removeAllTracks: () => void;
    storeSession: () => Promise<string | null>;
    subscriptions: CreatorSubscriptionType;
    toggleCreator: () => void;
    togglePause: () => void;
    trackLandingPageVisited: () => void;
    updateTrackBpm: (
        guid: string,
        bpm: number,
        interactionSource: string,
    ) => void;
    updateTrackBpmMultiplier: (
        id: string,
        bpmMultiplier: CreatorBpmMultiplier,
        interactionSource: string,
    ) => void;
    updateTrackGain: (guid: string, knobIndex: number) => void;
    updateTrackTranspose: (
        id: string,
        pitchTranspose: number,
        interactionSource: string,
    ) => void;
    generateMixdown: () => Promise<Blob | undefined>;
    cancelMixdown: () => void;
    setTrackUIReady: (guid: string) => void;
};

export type CreatorAction =
    | { type: 'newInstrumentationSessionId' }
    | { type: 'changeBPM'; payload: number }
    | {
          type: 'changeKey';
          payload: {
              root: KeyRootApi;
              quality: KeyQuality;
              sign: KeySign;
          };
      }
    | {
          type: 'addSamples';
          payload: {
              guids: string[];
              options?: {
                  onAdded?: () => void;
              };
              sample?: {
                  gain?: number;
                  bpm?: number;
                  pitchTranspose?: number;
              };
              isAutoload?: boolean;
          };
      }
    | {
          type: 'updateSample';
          payload: {
              guid: string;
              sample: Partial<CreatorSampleType>;
          };
      }
    | {
          type: 'updateSamples';
          payload: {
              samples: Map<string, Partial<CreatorSampleType>>;
          };
      }
    | {
          type: 'setFetchStatus';
          payload: { guid: string; fetchStatus: CreatorSampleFetchingStatuses };
      }
    | {
          type: 'setIsSampleUrlFetched';
          payload: string;
      }
    | {
          type: 'setStream';
          payload: { guid: string; stream: Response };
      }
    | {
          type: 'setStreamUrl';
          payload: { guid: string; streamUrl: string };
      }
    | {
          type: 'setIsAddedToSession';
          payload: string[];
      }
    | { type: 'removeSamples'; payload: string[] }
    | { type: 'removeAllSamples' };

export type CreatorSerializableSample = {
    guid: string;
    bpmMultiplier: CreatorBpmMultiplier;
    bpm?: number;
    gain: number;
    pitchTranspose: number | undefined;
};

export type CreatorSerializableKey = {
    quality: KeyQuality;
    sign: KeySign;
    root: KeyRootApi;
};

export type CreatorSerializableState = {
    bpm: number;
    keyQuality: KeyQuality;
    keySign: KeySign;
    keyRoot: KeyRootApi;
    samples: CreatorSerializableSample[];
};

export type CreatorStoredState = {
    version?: number;
    bpm: number;
    guid: string;
    key: CreatorSerializableKey;
    samples: CreatorSerializableSample[];
};
