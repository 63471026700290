import { useConvertExperiment as useConvertExperimentCore } from '@landr/react-core';
import { analytics } from 'utils/analytics';

export const useConvertExperiment = ({
    experimentId,
    variationId,
    skipExperimentCheck,
}: {
    experimentId?: string;
    variationId?: string;
    skipExperimentCheck?: boolean;
}) => {
    const experiment = useConvertExperimentCore({
        analytics: analytics,
        experimentId: experimentId,
    });

    // We need to validate that experiment.variationId exists before comparing it
    // Both experiment?.variationId and variationId can be undefined if they
    // are not found in the environment variables
    const isVariantId =
        !!experiment?.variationId && experiment.variationId === variationId;

    return {
        isVariantId: isVariantId && !skipExperimentCheck,
    };
};
