import React, { useContext } from 'react';
import { useSubscriptionsAndAllowedFeaturesProvider } from 'contexts/SubscriptionsAndAllowedFeaturesContext/SubscriptionsAndAllowedFeaturesContext';
import { CONFIG } from 'utils/config';
import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';
import { useConvertExperiment } from 'utils/convert/hooks/useConvertExperiment';

export type GuestToPaidContextType = {
    isVariantVisible: boolean;
    isLoading: boolean;
};

export const defaultGuestToPaidContext: GuestToPaidContextType = {
    isVariantVisible: false,
    isLoading: false,
};

export const GuestToPaidContext = React.createContext<GuestToPaidContextType>(
    defaultGuestToPaidContext,
);

export const useGuestToPaidContext = (): GuestToPaidContextType =>
    useContext(GuestToPaidContext);

export const GuestToPaidProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const { isAuthenticated, isLoading } = useAuthentication();
    const { upsellingAllowed, hasFetched } =
        useSubscriptionsAndAllowedFeaturesProvider();

    const { isVariantId } = useConvertExperiment({
        experimentId: CONFIG.VITE_GUEST_TO_PAID_EXPERIMENT_ID,
        variationId: CONFIG.VITE_GUEST_TO_PAID_VARIANT_ID,
        skipExperimentCheck: isAuthenticated && !upsellingAllowed,
    });

    const context = {
        isVariantVisible: !!isVariantId,
        isLoading: isLoading || !hasFetched,
    };

    return (
        <GuestToPaidContext.Provider value={context}>
            {children}
        </GuestToPaidContext.Provider>
    );
};

export default GuestToPaidProvider;
