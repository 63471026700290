import React, { useState, useEffect } from 'react';
import {
    PricingProvider,
    Languages,
    Environment,
    BillingCommitment,
} from '@landr/pricing';
import { auth } from 'utils/auth/auth';
import { i18n } from 'utils/setupTranslations';
import {
    getApplicationName,
    getIsDAWPlugin,
} from 'helpers/executionEnvironment';
import { CONFIG } from 'utils/config';
import { log } from 'utils/log';
import { getReturnUrl } from 'helpers';

const getSearchParams = (isFreeTrial?: boolean) => {
    let searchString = `?returnUrl=${getReturnUrl()}`;

    if (isFreeTrial) {
        searchString += `&isFreeTrial=${isFreeTrial}`;
    }

    return searchString;
};

const goToSubscriptionCheckout = ({
    code,
    commitment,
    couponCode,
    isFreeTrial,
    isDAWPlugin,
}: {
    code: string;
    commitment: BillingCommitment;
    couponCode: string | null;
    isFreeTrial?: boolean;
    isDAWPlugin: boolean;
}) => {
    const search = getSearchParams(isFreeTrial);

    const purchaseUrl = couponCode
        ? `${
              CONFIG.VITE_PURCHASE_URL
          }/${code}/${commitment.toLowerCase()}/${couponCode}${search}`
        : `${
              CONFIG.VITE_PURCHASE_URL
          }/${code}/${commitment.toLowerCase()}${search}`;

    isDAWPlugin
        ? window.open(purchaseUrl, '_blank')
        : window.location.assign(purchaseUrl);
};

const goToAccountPlans = (isDAWPlugin: boolean) => {
    isDAWPlugin
        ? window.open(`${CONFIG.VITE_ACCOUNT_PLANS_URL}`, '_blank')
        : window.location.assign(CONFIG.VITE_ACCOUNT_PLANS_URL);
};

export const handleSubscriptionPurchase = ({
    code,
    commitment,
    couponCode = null,
    isFreeTrial = false,
    isCurrentPlanAndPaused = false,
}: {
    code: string;
    commitment: BillingCommitment;
    couponCode?: string | null;
    isFreeTrial?: boolean;
    isCurrentPlanAndPaused?: boolean;
}) => {
    const isDAWPlugin = getIsDAWPlugin();

    // TODO: Gate checkout for current plan
    // https://mixgenius.atlassian.net/browse/CORE-12294
    if (isCurrentPlanAndPaused) {
        goToAccountPlans(isDAWPlugin);
    } else {
        goToSubscriptionCheckout({
            code,
            commitment,
            couponCode,
            isFreeTrial,
            isDAWPlugin,
        });
    }
};

export const PricingProviderWithToken = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [authorizationToken, setAuthorizationToken] = useState<
        string | undefined
    >(undefined);

    useEffect(() => {
        const getToken = async () => {
            const token = await auth.getAccessToken();

            setAuthorizationToken(token ?? undefined);
        };

        getToken();
    }, []);

    return (
        <PricingProvider
            authorizationToken={authorizationToken}
            language={i18n.locale as Languages}
            log={log}
            applicationName={getApplicationName()}
            env={CONFIG.VITE_APP_ENV as Environment}
            locationPathname={'/'}
            onSubscriptionPurchase={handleSubscriptionPurchase}
        >
            {children}
        </PricingProvider>
    );
};

export default PricingProviderWithToken;
