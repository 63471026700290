import { EventIds } from 'constants/eventIds';
import { useState, useCallback } from 'react';
import { useToggle } from '@landr/maestro';
import { useApolloClient } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { log } from 'utils/log';
import { useCreatorSession } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import { useActivePlayerContext } from 'contexts/ActivePlayerContext/ActivePlayerContext';
import {
    prepareStateForStoring,
    sanitizeParsedState,
} from '../helpers/misc.helpers';
import { convertHashToStoredState } from '../helpers/stateHash.helpers';
import {
    CreatorSampleMetaType,
    CreatorContextStateType,
    CreatorAction,
    CreatorStoredState,
    CreatorSerializableState,
} from '../Creator.types';
import { apolloGetSessionById, apolloStoreSession } from '../queries';
import { useAutoload } from './useAutoload';
import { useAutosave } from './useAutosave';

type UseCreatorSessionLoadingControllerType = {
    dispatchLoadSession: ({
        session,
        isAutoload,
        withAutoplay,
    }: {
        session?: CreatorSerializableState;
        isAutoload?: boolean;
        withAutoplay?: boolean;
    }) => void;
    loadSessionByGuid: ({
        sessionGuid,
        isAutoload,
        withAutoplay,
    }: {
        sessionGuid: string;
        isAutoload?: boolean;
        withAutoplay?: boolean;
    }) => Promise<void>;
    storeSession: () => Promise<string | null>;
    isLoadSessionModalOpen: boolean;
    closeLoadSessionModal: () => void;
    openLoadSessionModal: () => void;
};

export const useSessionLoadingHandlers = (
    state: CreatorContextStateType,
    dispatch: React.Dispatch<CreatorAction>,
    setIsSessionProcessing: (isProcessing: boolean) => void,
    openCreator: () => void,
): UseCreatorSessionLoadingControllerType => {
    const { stopActivePlayer } = useActivePlayerContext();

    // STATE
    const [pendingSessionToLoad, setPendingSessionToLoad] = useState(
        {} as CreatorSerializableState,
    );

    const {
        isActive: isLoadSessionModalOpen,
        activate: openLoadSessionModal,
        deactivate: deactivateLoadSessionModal,
    } = useToggle();

    const creatorSession = useCreatorSession();

    // APOLLO
    const apolloClient = useApolloClient();

    const closeLoadSessionModal = useCallback(() => {
        deactivateLoadSessionModal();
        setPendingSessionToLoad({} as CreatorSerializableState);
    }, [deactivateLoadSessionModal]);

    /**
     * Loading Session
     */

    const dispatchLoadSession = useCallback(
        ({
            session,
            isAutoload = false,
            withAutoplay = false,
        }: {
            session?: CreatorSerializableState;
            isAutoload?: boolean;
            withAutoplay?: boolean;
        }) => {
            const sessionToLoad = session || pendingSessionToLoad;

            if (!sessionToLoad) {
                return;
            }

            if (isLoadSessionModalOpen) {
                closeLoadSessionModal();
            }

            if (sessionToLoad?.samples?.length > 0) {
                // NOTE: this should be inform by the session, but the session is too slow
                setIsSessionProcessing(true);

                dispatch({ type: 'newInstrumentationSessionId' });

                dispatch({
                    type: 'removeAllSamples',
                });

                creatorSession.removeAllTracks();

                dispatch({
                    type: 'changeBPM',
                    payload: sessionToLoad.bpm,
                });

                dispatch({
                    type: 'changeKey',
                    payload: {
                        root: sessionToLoad.keyRoot,
                        quality: sessionToLoad.keyQuality,
                        sign: sessionToLoad.keySign,
                    },
                });

                if (sessionToLoad.samples.length) {
                    dispatch({
                        type: 'addSamples',
                        payload: {
                            guids: sessionToLoad.samples.map(
                                (sample) => sample.guid,
                            ),
                            isAutoload,
                        },
                    });

                    dispatch({
                        type: 'updateSamples',
                        payload: {
                            samples: [
                                ...sessionToLoad.samples.entries(),
                            ].reduce(
                                (updateMap, [_, track]) =>
                                    updateMap.set(track.guid, {
                                        bpm: track.bpm,
                                        bpmMultiplier: track.bpmMultiplier,
                                        gain: track.gain,
                                        pitchTranspose: track.pitchTranspose,
                                    }),
                                new Map<
                                    string,
                                    Partial<CreatorSampleMetaType>
                                >(),
                            ),
                        },
                    });

                    // play
                    withAutoplay && stopActivePlayer();
                    withAutoplay && creatorSession.unPause();
                }
            }
        },
        [
            dispatch,
            setIsSessionProcessing,
            closeLoadSessionModal,
            isLoadSessionModalOpen,
            pendingSessionToLoad,
            creatorSession,
            stopActivePlayer,
        ],
    );

    const loadSession = useCallback(
        (
            session: Partial<CreatorStoredState> | null,
            isAutoload = false,
            withAutoplay = false,
        ) => {
            if (!session?.samples) {
                (session?.bpm || session?.key) &&
                    log.error(
                        `Session has bpm and/or key, but no sample`,
                        EventIds.SessionWithoutSamples,
                        undefined,
                        session,
                    );

                return;
            }

            const sessionReadyToLoad = sanitizeParsedState(session);

            if (state.samples.size > 0) {
                setPendingSessionToLoad(sessionReadyToLoad);
                openLoadSessionModal();
            } else {
                dispatchLoadSession({
                    session: sessionReadyToLoad,
                    isAutoload,
                    withAutoplay,
                });
            }
        },
        [state.samples.size, openLoadSessionModal, dispatchLoadSession],
    );

    const loadSessionByGuid = useCallback(
        async ({
            sessionGuid,
            isAutoload,
            withAutoplay,
        }: {
            sessionGuid: string;
            isAutoload?: boolean;
            withAutoplay?: boolean;
        }) => {
            const sessionGuidTrimmed = sessionGuid.trim();

            if (!uuidValidate(sessionGuidTrimmed)) {
                log.warn(
                    `Invalid guid provide as session guid`,
                    EventIds.InvalidSessionGuid,
                    undefined,
                    { sessionGuidTrimmed },
                );
                return;
            }

            const session = await apolloGetSessionById(
                sessionGuidTrimmed,
                apolloClient,
            );

            loadSession(session, isAutoload, withAutoplay);

            if (state.samples.size === 0) {
                openCreator();
            }
        },
        [apolloClient, loadSession, state.samples.size, openCreator],
    );

    const loadSessionByHash = useCallback(
        (hash: string, isAutoload?: boolean) => {
            const session = convertHashToStoredState(hash);

            loadSession(session, true);
        },
        [loadSession],
    );

    const storeSession = useCallback(async (): Promise<string | null> => {
        const preparedState = prepareStateForStoring(state);

        const guid = await apolloStoreSession(preparedState, apolloClient);

        return guid;
    }, [state, apolloClient]);

    // AUTOSAVE AND LOAD
    useAutoload(
        loadSessionByGuid,
        loadSessionByHash,
        creatorSession.isInitialized,
    );

    useAutosave(state);

    return {
        dispatchLoadSession,
        loadSessionByGuid,
        storeSession,
        isLoadSessionModalOpen,
        closeLoadSessionModal,
        openLoadSessionModal,
    };
};
