import { t } from '@lingui/macro';

export const getPackSeoFallback = ({
    packName,
    artistName,
    isFree,
}: {
    packName: string;
    artistName?: string;
    isFree: boolean;
}) => {
    const isFreeWithArtistName = isFree && !!artistName;
    const isFreeWithoutArtistName = isFree && !artistName;
    const isPaidWithArtistName = !isFree && !!artistName;
    const isPaidWithoutArtistName = !isFree && !artistName;

    let title: string | undefined;
    let description: string | undefined;

    switch (true) {
        case isFreeWithArtistName:
            title = t`${packName} - ${artistName} Free Sample Pack`;
            description = t`Download ${packName} - ${artistName} free sample pack from LANDR Samples. Find the best free sample packs, loops, synths, vocals, drum kits and royalty free sound libraries to inspire your music production.`;
            break;

        case isFreeWithoutArtistName:
            title = t`${packName} Free Sample Pack`;
            description = t`Download ${packName} free sample pack from LANDR Samples. Find the best free sample packs, loops, synths, vocals, drum kits and royalty free sound libraries to inspire your music production.`;
            break;

        case isPaidWithArtistName:
            title = t`${packName} - ${artistName} Sample Pack`;
            description = t`Download ${packName} - ${artistName} sample pack from LANDR Samples. Get the best royalty free sample packs, loops, synths, vocals and drum kits from LANDR Samples.`;
            break;

        case isPaidWithoutArtistName:
            title = t`${packName} Sample Pack`;
            description = t`Download ${packName} sample pack from LANDR Samples. Get the best royalty free sample packs, loops, synths, vocals and drum kits from LANDR Samples.`;
            break;
    }

    return {
        titleFallback: title,
        descriptionFallback: description,
    };
};
