import { useEffect, useState, useCallback } from 'react';
import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';
import useCreatorRouteMatch from 'hooks/useCreatorRouteMatch';

const getLocalCreatorHash = (): string | null => {
    const creatorHash = localStorage.getItem('creator');

    localStorage.removeItem('creator');

    return creatorHash;
};

export function useAutoload(
    loadSessionByGuid: ({
        sessionGuid,
        isAutoload,
        withAutoplay,
    }: {
        sessionGuid: string;
        isAutoload?: boolean;
        withAutoplay?: boolean;
    }) => void,
    loadSessionByHash: (hash: string, isAutoload?: boolean) => void,
    creatorSessionIsInitialized: boolean,
): void {
    const [hasAutoloaded, setHasAutoloaded] = useState(false);
    const { isAuthenticated } = useAuthentication();

    // LOAD STATE HASH
    const routeMatch = useCreatorRouteMatch();

    const handleLoadSessionByGuid = useCallback(
        async (sessionGuid: string) => {
            loadSessionByGuid({ sessionGuid });
        },
        [loadSessionByGuid],
    );

    useEffect(() => {
        if (creatorSessionIsInitialized && !hasAutoloaded) {
            setHasAutoloaded(true);

            const sessionGuid = routeMatch?.params.session;
            const localCreatorHash = getLocalCreatorHash();

            if (sessionGuid) {
                handleLoadSessionByGuid(sessionGuid);
            } else if (localCreatorHash) {
                loadSessionByHash(localCreatorHash, true);
            }
        }
    }, [
        creatorSessionIsInitialized,
        hasAutoloaded,
        loadSessionByHash,
        isAuthenticated,
        routeMatch,
        handleLoadSessionByGuid,
    ]);
}
